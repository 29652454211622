import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useGetIndicatorDataQuery } from '../store/services/api';
import { errorToast } from '../utils/toast';
import { INDICATORS, VIZ_TYPES } from '../consts';

export const useFetchIndicatorData = ({
  category: categoryProp,
  geojsonPath,
  geoname,
  geotype,
  indicator: indicatorProp,
  selectedDemographicGroups,
  selectedMetric,
  vizType,
  year,
}) => {
  const { category: categoryParam, indicator: indicatorParam } = useParams();
  const [geojson, setGeojson] = useState(null);
  const [geojsonLoading, setGeojsonLoading] = useState(false);

  const getGeoName = () => {
    if (vizType === VIZ_TYPES.TRENDS || vizType === VIZ_TYPES.BY_YEAR) {
      return geoname;
    }
    // For the comparison chart we're comparing it to regions with the
    // same geotype, so we can ignore the geoname
    return null;
  };

  const getYear = () => {
    if (vizType === VIZ_TYPES.BY_YEAR || vizType === VIZ_TYPES.MAP_COMPARISON) {
      return year;
    }
    // Ignore whatever year is in state for the time series (trends) chart
    return null;
  };

  const category = categoryProp || categoryParam;
  const indicator = indicatorProp || indicatorParam;

  // Check to make sure the indicator exists in the list
  const isValidQuery = INDICATORS[category] && INDICATORS[category].indicators[indicator];

  const { data, error, isError, isFetching, isLoading } = useGetIndicatorDataQuery(
    {
      geoname: getGeoName(),
      geotype,
      indicator: isValidQuery ? indicator : '',
      year: getYear(),
    },
    {
      skip: !isValidQuery, // Don't query if no valid indicator
    },
  );

  useEffect(() => {
    const fetchGeojson = async () => {
      setGeojsonLoading(true);

      if (geojsonPath) {
        try {
          const req = await fetch(geojsonPath);
          const regionalGeojson = await req.json();
          setGeojson(regionalGeojson);
          setGeojsonLoading(false);
        } catch (error) {
          errorToast('Unable to fetch indicator data. Please try again later.');
          setGeojson(null);
          setGeojsonLoading(false);
        }
      } else {
        setGeojson(null);
        setGeojsonLoading(false);
      }
    };
    fetchGeojson();
  }, [geojsonPath]);

  useEffect(() => {
    if (isError) {
      errorToast('Unable to fetch indicator data. Please try again later.');
      console.error(error);
    }
  }, [error, isError]);

  // Memoize the filtered data so we're not running this filter fn more often than
  // necessary. Also helps w/ plotly performance to have the data ref stable
  const filteredData = useMemo(() => {
    const selectedGroupsHash = Object.fromEntries(
      selectedDemographicGroups.map(group => [group.value, true]),
    );
    let filteredRows = (data?.rows || []).filter(d => selectedGroupsHash[d.immigrant_status]);
    if (vizType === VIZ_TYPES.MAP_COMPARISON && selectedMetric) {
      filteredRows = filteredRows.filter(row => row.varname === selectedMetric);
    }
    return { ...data, rows: filteredRows };
  }, [data, selectedDemographicGroups, selectedMetric, vizType]);

  return {
    data: filteredData,
    geojson: geojsonPath ? geojson : null,
    isError,
    isFetching: isFetching || geojsonLoading,
    isLoading,
  };
};
