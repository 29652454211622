import { ArrowDownTrayIcon, ArrowTrendingUpIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';

import { VIZ_TYPES } from '../../consts';
import useCategoryAndIndicator from '../../hooks/useCategoryAndIndicator';
import { useFetchIndicatorData } from '../../hooks/useFetchIndicatorData';
import { downloadAsCSV, downloadChart } from '../../utils/download';
import Loader from '../general/Loader';
import Charts from './Charts';

import metadata from '../../metadata.json';

const ALL_YEARS_RAW = [
  2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
];

const YEARS = ALL_YEARS_RAW.map(year => ({ label: year, value: year }));

const TABS = [
  {
    icon: <ArrowTrendingUpIcon className="h-5 w-5 text-brand-700" />,
    key: VIZ_TYPES.TRENDS,
    label: 'Trends Over Time',
  },
  {
    icon: <ChartBarIcon className="h-5 w-5 text-brand-700" />,
    key: VIZ_TYPES.BY_YEAR,
    label: 'By Year',
  },
  {
    icon: (
      <svg className="h-5" viewBox="0 0 26 24" fill="none">
        <path
          className="stroke-green-700"
          d="M12.5 1H7V10.5H1L2 12L3.5 13.5L4.5 16L7.5 17V16L8.5 15H9.5L11 16L11.5 17L12.5 19L14 20L15.5 22.5L17.5 23L18.5 22.5L17.5 21.5V20L18.5 19L19.5 18V17H21L22.5 15L23.5 14.5L24.5 13.5V11.5L23.5 10.5V9L22.5 6H21H19.5H18.5H17H15.5L14 5H12.5V1Z"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    ),
    key: VIZ_TYPES.MAP_COMPARISON,
    label: 'In Comparison',
  },
];

const ChartSelector = ({ selectedDemographicGroups, selectedRegion, selectedSubRegion }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const vizType = searchParams.get('viz_type');

  const activeTabFramParams = TABS.findIndex(
    t => t.key.toLowerCase() === (vizType || '').toLowerCase(),
  );

  const activeTabIndex = activeTabFramParams === -1 ? 0 : activeTabFramParams;

  const setActiveTabParam = index =>
    setSearchParams(createSearchParams({ viz_type: TABS[index].key }), { replace: true });

  // Default to 2022 since most metrics don't have 2023 data
  const [selectedYear, setSelectedYear] = useState(YEARS[YEARS.length - 2]);

  // For map viz, show only one metric at a time to make things clearer
  const [selectedMetric, setSelectedMetric] = useState(null);

  const {
    indicators,
    isLoading: metadataIsLoading,
    variablesSortOrderByIndicator,
    yearsByIndicator,
  } = metadata;

  const { indicator, indicatorLabel } = useCategoryAndIndicator();

  const metricOptions = useMemo(
    () =>
      Object.keys(variablesSortOrderByIndicator?.[indicator] || {}).map(key => ({
        label: key,
        value: key,
      })),
    [indicator, variablesSortOrderByIndicator],
  );

  const availableYears = useMemo(
    () =>
      (yearsByIndicator?.[indicator] || []).map(year => ({
        label: year,
        value: year,
      })),
    [indicator, yearsByIndicator],
  );

  useEffect(() => {
    if (metricOptions.length) {
      setSelectedMetric(metricOptions[0]);
    }
  }, [metricOptions]);

  useEffect(() => {
    if (availableYears.length && !availableYears.find(year => year.value === selectedYear.value)) {
      // No matching year for the given indicator
      const lastAvailableYear = availableYears[availableYears.length - 1];
      setSelectedYear(lastAvailableYear);
    }
  }, [availableYears, selectedYear]);

  const activeTab = TABS[activeTabIndex];

  const tabRefs = useRef({});

  const setActiveTabParamAndFocus = index => {
    setActiveTabParam(index);
    if (tabRefs.current && tabRefs.current[index]) {
      tabRefs.current[index].focus();
    }
  };

  const { data, geojson, isFetching } = useFetchIndicatorData({
    geojsonPath: selectedRegion.geojsonPath,
    geoname: selectedSubRegion?.value,
    geotype: selectedRegion.value,
    selectedDemographicGroups,
    selectedMetric: selectedMetric?.value,
    vizType: TABS[activeTabIndex].key,
    year: selectedYear.value,
  });

  const definition = indicators?.[indicator]?.definition;
  const source = indicators?.[indicator]?.source;

  // Used to hide download buttons for map city view because we currently don't map city data
  // (city data doesn't really work as a choropleth)
  const isCityViewOnMap =
    selectedRegion.value === 'city' && TABS[activeTabIndex].key === VIZ_TYPES.MAP_COMPARISON;

  return (
    <div>
      <ul
        className="relative z-10 grid w-full grid-cols-3 items-stretch lg:flex lg:w-auto"
        role="tablist"
      >
        {TABS.map((tab, i) => (
          <li
            className="cursor-pointer border-l-4 border-r-4 border-brand-200 bg-brand-200 first:rounded-tl-md last:rounded-tr-md"
            key={tab.key}
            role="presentation"
          >
            <button
              aria-selected={i === activeTabIndex}
              className={`h-full w-full translate-y-[5px] rounded-t-md lg:w-56 ${tab === activeTab ? 'border-b border-white bg-white' : 'border-b border-brand bg-[#F5FBF1]'}`}
              onClick={() => setActiveTabParamAndFocus(i)}
              onKeyUp={(e, tab) => {
                e.preventDefault();
                const keyCode = e.keyCode;
                const nextTabIndex = i < TABS.length - 1 ? i + 1 : 0;
                const prevTabIndex = i === 0 ? TABS.length - 1 : i - 1;
                if (keyCode === 37) {
                  setActiveTabParamAndFocus(prevTabIndex);
                } else if (keyCode === 39) setActiveTabParamAndFocus(nextTabIndex);
              }}
              ref={element => (tabRefs.current[i] = element)}
              role="tab"
              tabIndex={i === activeTabIndex ? 0 : -1}
            >
              <div className="h-full rounded-t-md border-l border-r border-t border-brand p-2">
                <div className="flex h-full flex-col items-center gap-2 sm:flex-row sm:justify-center">
                  <div>{tab.icon}</div>
                  <div className="flex grow items-center text-center font-display text-xs font-light uppercase sm:grow-0 sm:text-left sm:text-sm">
                    <div>{tab.label}</div>
                  </div>
                </div>
              </div>
            </button>
          </li>
        ))}
      </ul>
      <div className="relative z-0 w-full rounded-b-md bg-brand-200 p-1 lg:rounded-tr-md">
        <div className="rounded-b-sm border border-brand">
          <div className="flex flex-col gap-4 bg-white px-2 py-4 md:px-8 md:py-12">
            <div className="flex flex-wrap justify-between gap-4 lg:flex-nowrap">
              <div>
                <div className="text-lg" id="chart-title-main">
                  {indicatorLabel}
                  {selectedSubRegion &&
                    TABS[activeTabIndex].key !== VIZ_TYPES.MAP_COMPARISON &&
                    ` -- ${selectedSubRegion.value}`}
                </div>
                <div className="text-sm font-light" id="chart-subtitle-main">
                  {definition}
                </div>
              </div>
              {[VIZ_TYPES.BY_YEAR, VIZ_TYPES.MAP_COMPARISON].includes(TABS[activeTabIndex].key) && (
                <div className="flex flex-wrap justify-between gap-3 md:justify-end lg:flex-nowrap">
                  {TABS[activeTabIndex].key === VIZ_TYPES.MAP_COMPARISON &&
                    metricOptions &&
                    metricOptions.length > 1 && (
                      <div className="flex flex-col gap-1">
                        <div className="font-display text-sm font-light">Select Metric</div>
                        <Select
                          className="w-56"
                          onChange={value => {
                            setSelectedMetric(value);
                          }}
                          options={metricOptions}
                          value={selectedMetric}
                        />
                      </div>
                    )}
                  <div className="flex flex-col gap-1">
                    <div className="font-display text-sm font-light">Select Year</div>
                    <Select
                      className="w-56"
                      onChange={value => setSelectedYear(value)}
                      options={availableYears}
                      value={selectedYear}
                    />
                  </div>
                </div>
              )}
            </div>

            {data && !isFetching && selectedDemographicGroups.length ? (
              <Charts
                chartType={TABS[activeTabIndex].key}
                data={data}
                geojson={geojson}
                geoType={selectedRegion.value}
              />
            ) : (
              <div className="flex h-96 w-full items-center justify-center">
                {!isFetching && !metadataIsLoading && selectedDemographicGroups.length === 0 ? (
                  <div className="font-display text-slate-800">
                    Select at least one demographic group to view charts
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            )}
            {Boolean(data.rows.length) && !isCityViewOnMap && (
              <div className="mt-6 flex flex-wrap items-center justify-between gap-4 md:flex-nowrap">
                <p className="font-display text-sm text-slate-800" id="chart-source">
                  Source: {source}
                </p>
                <div className="flex flex-wrap gap-2 sm:flex-nowrap">
                  <button
                    className="flex items-center gap-2 rounded-md border border-brand-500 bg-brand-50 px-2 py-1 font-display text-sm uppercase hover:bg-brand-100"
                    onClick={() => {
                      downloadChart({ downloadName: indicatorLabel });
                    }}
                  >
                    <ArrowDownTrayIcon className="h-6 w-6 text-brand-700" />
                    <div>{`Download Chart${(activeTab.key === VIZ_TYPES.MAP_COMPARISON && selectedDemographicGroups.length > 1) || (activeTab.key === VIZ_TYPES.TRENDS && metricOptions.length > 1) ? 's' : ''}`}</div>
                  </button>
                  <button
                    className="flex items-center gap-2 rounded-md border border-brand-500 bg-brand-50 px-2 py-1 font-display text-sm uppercase hover:bg-brand-100"
                    onClick={() => {
                      const downloadData = data.rows.map(row => {
                        const { data_type, sort_order, ...rest } = row;
                        return {
                          indicator: indicatorLabel,
                          definition: definition,
                          source: source,
                          ...rest,
                        };
                      });
                      downloadAsCSV(downloadData, `${indicatorLabel} Data`);
                    }}
                  >
                    <ArrowDownTrayIcon className="h-6 w-6 text-brand-700" />
                    <div>Download Chart Data</div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartSelector;
