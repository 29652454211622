import { Fragment } from 'react';
import SectionHeaderWithLine from '../components/general/SectionHeaderWithLine';
import SectionLayout from '../components/general/SectionLayout';

const METRIC_DETAILS = [
  {
    title: 'Demographics',
    metrics: [
      {
        label: 'Population',
        description:
          'The total number of residents in Texas, including both U.S.-born and immigrant populations, is critical to understanding how demographic shifts affect local communities, businesses, and public services.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Age Group',
        description:
          'This metric provides the share of residents by age group, offering insights into the age distribution of both immigrant and U.S.-born populations. This helps assess the needs of Texans in areas like education, healthcare, and workforce development.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Race-Ethnicity',
        description:
          'Understanding the racial and ethnic makeup of Texas residents is essential for recognizing the diversity of the population, including both immigrant and U.S.-born communities. This data supports resource allocation and policy decisions that reflect the needs of all Texans.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Place of Birth',
        description:
          'This metric breaks down Texas’s foreign-born population by region of birth, illustrating the global connections that contribute to the state’s growth and cultural landscape.',
        source: 'American Community Survey, Table B05006',
        sourceLink: '',
      },
      {
        label: 'English Proficiency',
        description:
          'Limited English proficiency can isolate immigrant communities and hinder access to services, education, and employment. This metric shows the share of residents who speak English less than “very well,” helping identify areas where language support may be needed.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Economic Contributions (Taxes and Spending Power)',
        description:
          'Limited English proficiency can isolate immigrant communities and hinder access to services, education, and employment. This metric shows the share of residents who speak English less than “very well,” helping identify areas where language support may be needed.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
    ],
  },
  {
    title: 'Economic Mobility',
    metrics: [
      {
        label: 'Child Poverty',
        description:
          'This metric represents the share of families with children living below the federal poverty line, highlighting economic hardship among both immigrant and U.S.-born families. Understanding child poverty is key to creating opportunities for all Texas children to thrive.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Class of Worker',
        description:
          'This metric breaks down the share of civilian workers by class of worker, offering insights into how immigrant and U.S.-born workers are represented across private, government, and self-employed sectors.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Educational Attainment',
        description:
          'The share of Texas residents aged 25 and older by their highest level of education. This data highlights educational achievements and gaps, providing insights into the skills that both immigrant and U.S.-born populations bring to the workforce.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Employment Status',
        description:
          'This metric shows the share of civilians aged 16 and older in the labor force who are unemployed. Tracking unemployment across immigrant and U.S.-born populations provides a clear indicator of the state’s economic health.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Homeownership',
        description:
          'Homeownership is a key indicator of financial stability and long-term investment in communities. This metric examines the share of households living in owner-occupied homes, offering insights into the wealth-building of immigrant and U.S.-born families.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Housing Cost-Burdened',
        description:
          'This metric shows the share of households spending 30% or more of their income on housing. It highlights economic pressures faced by both immigrant and U.S.-born families and where housing affordability is a concern.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Income',
        description:
          'Median household income provides a snapshot of financial health across communities. Understanding income distribution helps reveal economic disparities between immigrant and U.S.-born households.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Industry',
        description:
          'This metric categorizes the share of civilian workers by industry, showing which sectors employ the most workers, including immigrants and U.S.-born residents.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Labor Force Participation',
        description:
          'The share of civilians aged 16 and over who are either employed or looking for work. This metric reflects the degree to which immigrant and U.S.-born residents are engaged in the workforce.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Occupation',
        description:
          'This metric shows the share of civilian workers by occupation, offering insights into the roles and skills immigrant and U.S.-born workers bring to Texas’s economy.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Poverty',
        description:
          'This metric highlights the share of Texas residents living below the federal poverty line, helping to identify where economic challenges are most severe for both immigrant and U.S.-born communities.',
        source: 'American Community Survey, Table S0501',
        sourceLink: 'https://data.census.gov/table/ACSST1Y2023.S0501?q=S0501&g=040XX00US48',
      },
      {
        label: 'Economic Contributions (Taxes and Spending Power)',
        description:
          'Immigrant communities contribute to the Texas economy through their taxes and spending power. This metric estimates the economic impact of immigrants at the county, MSA, and state levels.',
        source: 'January Advisors’ analysis of ACS microdata via IPUMS',
        sourceLink: '',
      },
    ],
  },
  {
    title: 'Legal Process',
    metrics: [
      {
        label: 'Immigrants in Immigration Court',
        description:
          'This metric shows the number of immigrants currently in removal proceedings, including those seeking relief such as asylum. It highlights the complexity of navigating the immigration legal system.',
        source: 'Department of Justice, EOIR',
        sourceLink: 'https://www.justice.gov/eoir/foia-library-0',
      },
      {
        label: 'Custody Status',
        description:
          'The share of individuals in removal proceedings by their custody status, including those detained, never detained, or released. It provides context on the varying conditions faced by immigrants during the legal process.',
        source: 'Department of Justice, EOIR',
        sourceLink: 'https://www.justice.gov/eoir/foia-library-0',
      },
      {
        label: 'Type of Legal Protection from Deportation',
        description:
          'This metric categorizes individuals by the type of relief they are seeking in immigration court, such as asylum or other forms of relief. It underscores the importance of filing for relief as part of the immigration process.',
        source: 'Department of Justice, EOIR',
        sourceLink: 'https://www.justice.gov/eoir/foia-library-0',
      },
      {
        label: 'Legal Representation',
        description:
          'The share of immigrants in removal proceedings with legal representation. Access to legal counsel can significantly impact the outcome of a case, highlighting the need for resources in navigating the system.',
        source: 'Department of Justice, EOIR',
        sourceLink: 'https://www.justice.gov/eoir/foia-library-0',
      },
      {
        label: 'Country of Origin',
        description:
          'This metric shows the share of immigrants in immigration court by their country of origin, offering insights into the global and regional factors influencing migration to Texas.',
        source: 'Department of Justice, EOIR',
        sourceLink: 'https://www.justice.gov/eoir/foia-library-0',
      },
      {
        label: 'Age Group',
        description:
          'The share of immigrants in removal proceedings by age group, offering a perspective on how age impacts the immigration process, including specific vulnerabilities for children, young adults, and older individuals.',
        source: 'Department of Justice, EOIR',
        sourceLink: 'https://www.justice.gov/eoir/foia-library-0',
      },
    ],
  },
];

const sectionHeaderClass = 'mt-4 mb-2 font-display text-3xl font-medium md:mt-8 md:mb-4';
const pClass = 'mb-4';
const highlight = 'font-bold font-display';

const About = () => {
  return (
    <SectionLayout>
      <SectionHeaderWithLine className="mb-6 mt-2" background="white">
        Overview of the Tool and Data
      </SectionHeaderWithLine>
      <p className={pClass}>
        The Texas Immigration Data Portal is a comprehensive, nonpartisan resource that offers users
        access to detailed immigration data from across Texas. By combining federal datasets with
        advanced data visualization tools, the portal allows users to explore trends in immigration
        over time, across multiple geographic levels, and within various sectors, including
        demographics, economic impact, and legal processes. The portal’s goal is to provide reliable
        and accessible data to inform the public, policymakers, and community organizations about
        the role of immigration in shaping Texas’s present and future.
      </p>
      <h1 className={sectionHeaderClass}>Data Sources</h1>
      <p className={pClass}>
        The data featured on the portal comes from a combination of widely respected and trusted
        federal sources, ensuring both reliability and breadth in the data available.
      </p>
      <ul>
        <li>
          <p className={pClass}>
            <span className={highlight}>American Community Survey (ACS):</span> The{' '}
            <a href="https://www.census.gov/programs-surveys/acs" target="_blank" rel="noreferrer">
              ACS
            </a>
            is conducted by the U.S. Census Bureau and provides a continuous, detailed snapshot of
            the U.S. population. It offers data on a wide range of demographic, social, and economic
            characteristics, including age, education, housing, and employment, all of which are
            broken down by immigration status. The ACS is especially valuable for understanding
            long-term trends in immigrant communities.
          </p>
        </li>
        <li>
          <p className={pClass}>
            <span className={highlight}>IPUMS Microdata:</span> To enhance the granularity of the
            data, we also use microdata from the ACS via the{' '}
            <a href="https://usa.ipums.org/usa/" target="_blank" rel="noreferrer">
              IPUMS platform
            </a>
            . IPUMS allows us to delve deeper into individual-level data, providing more detailed
            insights into specific subgroups and allowing for custom analyses of the Texas immigrant
            population.
          </p>
        </li>
        <li>
          <p className={pClass}>
            <span className={highlight}>Executive Office for Immigration Review (EOIR):</span> The{' '}
            <a href="https://www.justice.gov/eoir/foia-library-0" target="_blank" rel="noreferrer">
              EOIR dataset
            </a>
            , provided by the U.S. Department of Justice, covers all immigration court cases in the
            United States. This data offers insights into the legal processes immigrants face,
            including removal proceedings, asylum claims, and other forms of relief sought. By
            including EOIR data, the portal highlights the legal challenges and outcomes for
            immigrants in Texas, providing a fuller picture of the immigrant experience.
          </p>
        </li>
      </ul>
      <h1 className={sectionHeaderClass}>Geography</h1>
      <p className={pClass}>
        The Texas Immigration Data Portal provides detailed breakdowns of data across multiple
        geographic levels, offering users the ability to explore immigration trends at the following
        scales:
      </p>
      <ul className="mb-4 ml-8 list-disc font-display [&_li]:pl-2">
        <li>State</li>
        <li>Metropolitan Statistical Area (MSA)</li>
        <li>City</li>
        <li>County</li>
        <li>Congressional District</li>
        <li>State House District</li>
        <li>State Senate District</li>
      </ul>
      <p className={pClass}>
        However, not all cities and counties are available due to small sample sizes. The U.S.
        Census and American Community Survey (ACS) do not report data for areas where the population
        of immigrants is too small to produce reliable estimates. This limitation is particularly
        relevant for rural areas and smaller cities where the immigrant population may be
        underreported.
      </p>
      <p className={pClass}>
        For Congressional District, State House, and State Senate, we display data based on the most
        recent district boundaries that were redrawn following the 2020 census. As a result, data at
        these levels are only available for the 2020-2022 period. Users should be aware that
        historical comparisons before 2020 are not possible at these legislative boundaries due to
        the redistricting.
      </p>
      <p className={pClass}>
        This geographic breakdown helps ensure that users can explore immigration trends across
        multiple layers of Texas’s diverse population, providing insights into how immigration
        affects communities large and small.
      </p>
      <h1 className={sectionHeaderClass}>Metric Details</h1>
      {METRIC_DETAILS.map(category => (
        <Fragment key={category.title}>
          <h2 className="mb-2 font-display text-2xl font-bold">{category.title}</h2>
          {category.metrics.map(metric => (
            <div className="mb-4" key={metric.label}>
              <h3 className="mb-1 font-display font-bold">{metric.label}</h3>
              <p>{metric.description}</p>
              <p>
                <span className="font-display">Source: </span>
                {metric.sourceLink ? (
                  <a
                    className="text-brand-700 underline"
                    href={metric.sourceLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {metric.source}
                  </a>
                ) : (
                  metric.source
                )}
              </p>
            </div>
          ))}
        </Fragment>
      ))}
    </SectionLayout>
  );
};

export default About;
