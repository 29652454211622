import { Link } from 'react-router-dom';

import { INDICATORS } from '../consts';

const ExploreMenuAlt = () => {
  return (
    <div>
      <h2 className="font-display text-2xl font-medium">Explore Other Indicators</h2>
      <div className="p-y-8 mt-8 grid grid-cols-2 gap-x-4 md:grid-cols-3">
        {Object.entries(INDICATORS).map(([key, { label, indicators }]) => (
          <ul key={key}>
            <li className="mb-6 font-display font-medium underline decoration-brand decoration-2 underline-offset-8">
              {label}
            </li>
            <ul className="flex flex-col gap-3">
              {Object.entries(indicators).map(([indicatorKey, { label: indicatorLabel }]) => (
                <li key={indicatorKey}>
                  <Link to={`/explore/${key}/${indicatorKey}`}>{indicatorLabel}</Link>
                </li>
              ))}
            </ul>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default ExploreMenuAlt;
