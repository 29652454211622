import { Link } from 'react-router-dom';

const pClass = 'mb-4';

export const AboutData = () => (
  <div className="mt-8 md:mt-12">
    <p className={pClass}>
      Texas Immigration Law Council relies on publicly available data from trusted federal sources,
      including the U.S. Census, the American Community Survey (ACS), and the Department of
      Justice’s Executive Office for Immigration Review (EOIR) dataset. These sources are considered
      the gold standard for demographic and immigration data. The Census and ACS provide
      comprehensive and regularly updated data on population, economic, and social trends, ensuring
      a reliable snapshot of immigrant communities across Texas. The EOIR dataset offers detailed
      insights into immigration court proceedings, helping us better understand the legal landscape
      for immigrants.
    </p>
    <p className={pClass}>
      However, it’s important to note that not all geographic levels are covered in these datasets.
      In less populous rural areas, for instance, the ACS may not publish data if there are too few
      immigrants, which can limit detailed reporting at these local levels. This is a common
      challenge when working with sample-based data, but it allows for a focus on areas where the
      data is statistically reliable.
    </p>
    <p className={pClass}>
      At Texas Immigration Law Council, we are committed to transparency and accuracy. We strive to
      provide the best possible data while being clear about any limitations. We value your feedback
      and will continue to expand and update the data regularly, ensuring you have the tools and
      insights needed to understand immigration in Texas.
    </p>
    <p className={pClass}>
      You’re invited to explore the data and share your insights with us. As we continue to expand
      this resource, your feedback helps ensure the portal meets the needs of Texans across the
      state. We aim to provide accurate, accessible data that supports informed discussions on
      immigration.
    </p>
    <p className={pClass}>
      For more information, please visit the{' '}
      <Link className="text-brand-700 underline" to="/about">
        About
      </Link>{' '}
      page.
    </p>
  </div>
);
