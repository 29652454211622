import { FEATURE_OUTLINE_COLOR, TRANSPARENT, VIZ_TYPES } from '../consts';

// Takes our flat data rows and nests them by key values
// This makes it a lot easier to group our data by
// indicator and immigrant_status. Then we can map
// through it to generate our grouped plots or plotly traces.
export const nestBy = (data = [], key1, key2) => {
  return (data || []).reduce((a, b) => {
    if (!a[b[key1]]) {
      a[b[key1]] = {};
    }
    if (!a[b[key1]][b[key2]]) {
      a[b[key1]][b[key2]] = [];
    }
    a[b[key1]][b[key2]].push(b);
    return a;
  }, {});
};

// Get layout consts for plotly
export const getChartLayout = ({ chartType, dataType, domainMax, useGeojson }) => {
  // Layout for choropleth charts
  if (chartType === VIZ_TYPES.MAP_COMPARISON) {
    return {
      autosize: true, // Ensures responsive viz (NB: All parent divs must have w-full for responsivity)
      dragmode: false,
      hoverlabel: {
        // Set background of tooltip (defaults to trace color otherwise)
        bgcolor: '#FFF',
      },
      margin: {
        r: 0,
        t: 0,
        l: 0,
        b: 0,
      },
      // https://plotly.com/javascript/reference/layout/geo/#layout-geo
      geo: {
        fitbounds: useGeojson ? 'geojson' : false,
        projection: {
          type: 'albers usa',
        },
        lakecolor: TRANSPARENT,
        scope: 'usa',
        showlakes: false,
        subunitcolor: useGeojson ? TRANSPARENT : FEATURE_OUTLINE_COLOR,
        subunitwidth: 2,
      },
    };
  }

  // Layout for line and bar charts
  return {
    autosize: true, // Ensures responsive viz (NB: All parent divs must have w-full for responsivity)
    bargroupgap: 0.1, // Leave a little gap between bars for grouped bar charts
    hoverlabel: {
      // Set background of tooltip (defaults to trace color otherwise)
      bgcolor: '#FFF',
    },
    legend: {
      // These legend props help to ensure that the legend doesn't overflow
      // the tick text
      yref: 'container',
      yanchor: 'bottom',
      xanchor: 'center',
      x: 0.5,
      orientation: 'h',
    },
    margin: {
      r: 8,
      t: 8, // Have to leave a little margin at the top to keep things nice
      l: 0,
    },
    xaxis: {
      automargin: true, // Auto-fits axes
      fixedrange: true, // Prevents users from resizing plot on drag
      ticklen: 4,
      tickwidth: 1,
      type: 'category',
    },
    yaxis: {
      automargin: true, // Auto-fits axes
      fixedrange: true, // Prevents users from resizing plot on drag
      range: [0, domainMax],
      tickformat: dataType === 'percent' ? '.0%' : dataType === 'dollars' ? '$,.0f' : null,
    },
  };
};
