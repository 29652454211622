import { Link, useMatch } from 'react-router-dom';

import { DEFAULT_CATEGORY, DEFAULT_INDICATOR } from '../consts';

const Header = () => {
  const exploreMatch = useMatch('/explore/*');
  const aboutMatch = useMatch('/about/*');

  return (
    <div className="sticky top-0 z-50 flex items-center justify-between bg-[rgba(255,255,255,0.95)] px-4 py-4 font-display shadow-md backdrop-blur-sm md:px-6">
      <Link to="/" className="text-xl font-medium uppercase md:text-2xl">
        Texas Immigration Data Portal
      </Link>
      <nav className="flex gap-8 text-base decoration-brand decoration-2 underline-offset-2 md:text-xl [&_.active]:underline">
        <Link
          className={exploreMatch ? 'active' : ''}
          to={`./explore/${DEFAULT_CATEGORY}/${DEFAULT_INDICATOR}`}
        >
          Explore the Data
        </Link>
        <Link className={aboutMatch ? 'active' : ''} to="./about">
          About
        </Link>
      </nav>
    </div>
  );
};

export default Header;
