import useCategoryAndIndicator from '../hooks/useCategoryAndIndicator';
import metadata from '../metadata.json';

const IndicatorHeader = () => {
  const { category, indicator, indicatorLabel } = useCategoryAndIndicator();
  const { indicators } = metadata;
  return (
    <div>
      <div className="font-display text-lg font-light">{category.label}</div>
      <div className="flex items-center gap-4">
        <div className="font-display text-3xl font-medium">{indicatorLabel}</div>
        <div className="h-0.5 grow bg-brand" />
      </div>
      <p className="mt-4">{indicators?.[indicator]?.description}</p>
    </div>
  );
};
export default IndicatorHeader;
