const Loader = () => (
  <svg className="h-8 w-8 animate-spin" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="10" stroke="#EAF6E3" strokeWidth="4" />
    <mask id="path-2-inside-1_215_3464" fill="white">
      <path d="M24 12C24 10.4241 23.6896 8.86371 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40042 18.0481 1.5165 16.5922 0.913445C15.1363 0.310389 13.5759 -6.88831e-08 12 0L12 3.96019C13.0558 3.96019 14.1013 4.16814 15.0767 4.57218C16.0521 4.97622 16.9384 5.56843 17.685 6.31499C18.4316 7.06156 19.0238 7.94786 19.4278 8.9233C19.8319 9.89873 20.0398 10.9442 20.0398 12H24Z" />
    </mask>
    <path
      d="M24 12C24 10.4241 23.6896 8.86371 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40042 18.0481 1.5165 16.5922 0.913445C15.1363 0.310389 13.5759 -6.88831e-08 12 0L12 3.96019C13.0558 3.96019 14.1013 4.16814 15.0767 4.57218C16.0521 4.97622 16.9384 5.56843 17.685 6.31499C18.4316 7.06156 19.0238 7.94786 19.4278 8.9233C19.8319 9.89873 20.0398 10.9442 20.0398 12H24Z"
      stroke="#7EC858"
      strokeWidth="8"
      mask="url(#path-2-inside-1_215_3464)"
    />
  </svg>
);

export default Loader;
