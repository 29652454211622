import DataGlance from "../components/DataGlance";
import ExploreCTA from "../components/ExploreCTA";
import Hero from "../components/Hero";
import Methodology from "../components/Methodology";

const Main = () => {
  return (
    <>
      <Hero />
      <ExploreCTA />
      <DataGlance />
      <Methodology />
    </>
  );
};

export default Main;
