// We hard code the indicators (rather than fetch from server) because
// we don't want to wait for the list and the label to load.
// The object key must match the key in the db
export const INDICATORS = {
  demographics: {
    label: 'Demographics',
    indicators: {
      population: {
        label: 'Population',
      },
      'age-group': {
        label: 'Age Group',
      },
      'race-ethnicity': {
        label: 'Race-ethnicity',
      },
      'place-of-birth': {
        label: 'Place of Birth',
      },
      'english-proficiency': {
        label: 'English Proficiency',
      },
    },
  },
  'economic-mobility': {
    label: 'Economic Mobility',
    indicators: {
      'economic-contributions': {
        label: 'Economic Contributions',
      },
      'educational-attainment': {
        label: 'Educational Attainment',
      },
      'labor-force-participation': {
        label: 'Labor Force Participation',
      },
      'employment-status': {
        label: 'Employment Status',
      },
      'class-of-worker': {
        label: 'Class of Worker',
      },
      occupation: {
        label: 'Occupation',
      },
      industry: {
        label: 'Industry',
      },
      income: {
        label: 'Income',
      },
      poverty: {
        label: 'Poverty',
      },
      'child-poverty': {
        label: 'Child Poverty',
      },
      homeownership: {
        label: 'Homeownership',
      },
      'housing-cost-burdened': {
        label: 'Housing Cost-burdened',
      },
    },
  },
  'legal-process': {
    label: 'Legal Process',
    indicators: {
      'immigrants-in-immigration-court-doj': {
        label: 'Immigrants in Immigration Court (DOJ)',
      },
      'age-group-doj': {
        label: 'Age Group (DOJ)',
      },
      'country-of-origin-doj': {
        label: 'Country-of-origin (DOJ)',
      },
      'custody-status-doj': {
        label: 'Custody Status (DOJ)',
      },
      'type-of-legal-protection-from-deportation-doj': {
        label: 'Type of Legal Protection from Deportation (DOJ)',
      },
      'legal-representation-doj': {
        label: 'Legal Representation (DOJ)',
      },
    },
  },
};

export const DEFAULT_CATEGORY = 'economic-mobility';
export const DEFAULT_INDICATOR = 'educational-attainment';

export const VIZ_TYPES = {
  TRENDS: 'TRENDS',
  BY_YEAR: 'BY_YEAR',
  MAP_COMPARISON: 'MAP_COMPARISON',
};

const ALL_RESIDENTS = 'All residents';
const IMMIGRANT = 'Immigrant';
const IMMIGRANT_REMOVAL_PROC = 'Immigrant in removal proceedings';
const NATURALIZED = 'Naturalized US citizen';
const NOT_CITIZEN = 'Not a US citizen';
const US_BORN = 'US-born citizen';

export const CATEGORICAL_COLORS = {
  [ALL_RESIDENTS]: '#F4A938',
  [IMMIGRANT]: '#6CAB4C',
  [IMMIGRANT_REMOVAL_PROC]: '#EB71FF',
  [NATURALIZED]: '#4B68C9',
  [NOT_CITIZEN]: '#76CAB2',
  [US_BORN]: '#E66D59',
};

export const GEOTYPE_TO_LABELS = {
  'united-states': 'United States',
  state: 'State',
  city: 'City',
  county: 'County',
  'congressional-district': 'Congressional District',
  'state-senate-district': 'State Senate',
  'state-house-district': 'State House',
  msa: 'Metro Area',
};

export const FIPS_TO_STATE_ABBR = {
  '01': 'AL',
  '02': 'AK',
  '04': 'AZ',
  '05': 'AR',
  '06': 'CA',
  '08': 'CO',
  '09': 'CT',
  10: 'DE',
  11: 'DC',
  12: 'FL',
  13: 'GA',
  15: 'HI',
  16: 'ID',
  17: 'IL',
  18: 'IN',
  19: 'IA',
  20: 'KS',
  21: 'KY',
  22: 'LA',
  23: 'ME',
  24: 'MD',
  25: 'MA',
  26: 'MI',
  27: 'MN',
  28: 'MS',
  29: 'MO',
  30: 'MT',
  31: 'NE',
  32: 'NV',
  33: 'NH',
  34: 'NJ',
  35: 'NM',
  36: 'NY',
  37: 'NC',
  38: 'ND',
  39: 'OH',
  40: 'OK',
  41: 'OR',
  42: 'PA',
  44: 'RI',
  45: 'SC',
  46: 'SD',
  47: 'TN',
  48: 'TX',
  49: 'UT',
  50: 'VT',
  51: 'VA',
  53: 'WA',
  54: 'WV',
  55: 'WI',
  56: 'WY',
  60: 'AS',
  66: 'GU',
  72: 'PR',
  78: 'VI',
};

export const FEATURE_OUTLINE_COLOR = '#d4d4d4';
export const TRANSPARENT = 'rgba(255,255,255,0)';
