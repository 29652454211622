import Select from 'react-select';

const labelClass = 'font-display font-light text-sm';
const selectClass = 'min-w-56 max-w-80';

const DemographicGroupSelector = ({
  demographicGroupOptions,
  selectedDemographicGroups,
  setSelectedDemographicGroups,
}) => {
  return (
    <div className="flex grow flex-col items-start gap-2 md:items-end">
      <div className={labelClass}>Select Demographic Groups</div>
      <Select
        className={selectClass}
        isMulti
        options={demographicGroupOptions}
        isClearable={false}
        isSearchable={false}
        onChange={value => {
          setSelectedDemographicGroups(value);
        }}
        value={selectedDemographicGroups}
      />
    </div>
  );
};

export default DemographicGroupSelector;
