import { AboutData } from './AboutData';
import SectionLayout from './general/SectionLayout';

const Methodology = () => {
  return (
    <SectionLayout>
      <h3 className="mb-4 font-display text-3xl font-medium md:mb-8">About the Data</h3>
      <AboutData />
    </SectionLayout>
  );
};

export default Methodology;
