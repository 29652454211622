import { StarIcon } from '@heroicons/react/24/outline';
import SectionLayout from './general/SectionLayout';

const Hero = () => {
  return (
    <div className="overflow-auto bg-hero bg-cover bg-center">
      <div className="h-full w-full overflow-auto bg-gradient-to-r from-[rgba(85,209,97,0.25)] to-[rgba(85,209,97,0.15)]">
        <SectionLayout>
          <div className="flex w-full max-w-full flex-col gap-2 bg-[rgba(255,255,255,0.95)] p-4 md:w-3/5 md:gap-4 md:p-8 xl:w-1/2">
            <h1
              className="font-display text-3xl font-medium uppercase md:text-5xl"
              style={{ lineHeight: '1.2em' }}
            >
              Texas Immigration
              <br />
              Data Portal
            </h1>
            <div className="flex items-center gap-3">
              <StarIcon className="size-6 -rotate-45 text-brand" />
              <div className="h-0.5 grow bg-brand" />
            </div>
            <p>
              The Texas Immigration Data Portal, powered by the Texas Immigration Law Council, is
              your nonpartisan source for exploring immigration data in Texas. Dive into the
              numbers, visualize trends, and download the data you need.
            </p>
          </div>
        </SectionLayout>
      </div>
    </div>
  );
};

export default Hero;
