const defaultState = {};

const generalReducer = (state = defaultState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default generalReducer;
