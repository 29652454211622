const SectionHeaderWithLine = ({ background = 'grey', className, children }) => {
  return (
    <div className={`relative flex w-full justify-center ${className}`}>
      <div className="absolute top-1/2 h-0.5 w-full bg-brand"></div>
      <h3
        className={`relative z-10 inline-block px-4 font-display text-2xl font-medium ${background === 'grey' ? 'bg-gray-200' : 'bg-white'}`}
      >
        {children}
      </h3>
    </div>
  );
};
export default SectionHeaderWithLine;
