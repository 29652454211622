import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import About from './pages/About';
import Explore from './pages/Explore';
import Main from './pages/Main';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/explore/:category/:indicator" element={<Explore />} />
        <Route path="/about" element={<About />} />
        <Route path="/*" element={<Main />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
