import { useEffect, useState } from 'react';

import { GEOTYPE_TO_LABELS } from '../../consts';
import useCategoryAndIndicator from '../../hooks/useCategoryAndIndicator';
import ChartSelector from './ChartSelector';
import DemographicGroupSelector from './DemographicGroupSelector';
import RegionSelector from './RegionSelector';

import metadata from '../../metadata.json';

const STATE_SUB_REGIONS = [{ label: 'Texas', value: 'Texas' }];

const ChartSection = () => {
  const { geoTypes, statusesByIndicator } = metadata;
  const { indicator } = useCategoryAndIndicator();

  const [regionOptions, setRegionOptions] = useState([
    {
      label: 'State',
      subRegions: STATE_SUB_REGIONS,
      value: 'state',
    },
  ]);
  const [selectedRegion, setSelectedRegion] = useState(regionOptions[0]);
  const [selectedSubRegion, setSelectedSubRegion] = useState(STATE_SUB_REGIONS[0]);

  const [availableDemographicGroups, setAvailableDemographicGroups] = useState([]);
  const [selectedDemographicGroups, setSelectedDemographicGroups] = useState([]);

  useEffect(() => {
    if (!statusesByIndicator) {
      return;
    }
    const immigrantStatusGroupsForIndicator = statusesByIndicator[indicator];
    if (immigrantStatusGroupsForIndicator) {
      const options = immigrantStatusGroupsForIndicator.map(option => ({
        label: option,
        value: option,
      }));
      setAvailableDemographicGroups(options);
      setSelectedDemographicGroups(options.slice(0, 2));
    }
  }, [indicator, statusesByIndicator]);

  // When we get geoTypes data back from the server, set the available regions for the dropdowns
  useEffect(() => {
    if (geoTypes) {
      const countySubRegions = geoTypes.county.map(county => ({ label: county, value: county }));

      // The district names are sorted alphabetically, which puts district 1 and district 10 next
      // to each other in the list. Instead do a custom sort on these labels
      const congressionalSubRegions = geoTypes['congressional-district']
        .map(district => ({
          label: district,
          value: district,
        }))
        .sort((a, b) => {
          const districtA = parseInt(a.label.split('Congressional District ')[1].split([' '])[0]);
          const districtB = parseInt(b.label.split('Congressional District ')[1].split([' '])[0]);
          return districtA - districtB;
        });

      const stateSenateSubRegions = geoTypes['state-senate-district']
        .map(district => ({
          label: district,
          value: district,
        }))
        .sort((a, b) => {
          const districtA = parseInt(a.label.split('State Senate District ')[1].split([' '])[0]);
          const districtB = parseInt(b.label.split('State Senate District ')[1].split([' '])[0]);
          return districtA - districtB;
        });

      const stateHouseSubRegions = geoTypes['state-house-district']
        .map(district => ({
          label: district,
          value: district,
        }))
        .sort((a, b) => {
          const districtA = parseInt(a.label.split('State House District ')[1].split([' '])[0]);
          const districtB = parseInt(b.label.split('State House District ')[1].split([' '])[0]);
          return districtA - districtB;
        });

      const msaSubRegions = geoTypes['msa'].map(area => ({ label: area, value: area }));

      const citySubRegions = geoTypes['city'].map(city => ({ label: city, value: city }));

      const regionOptions = [
        {
          label: GEOTYPE_TO_LABELS['state'],
          value: 'state',
          subRegions: STATE_SUB_REGIONS,
        },
        {
          label: GEOTYPE_TO_LABELS['county'],
          value: 'county',
          subRegions: countySubRegions,
          defaultSubRegion: countySubRegions.find(region => region.label === 'Harris'),
          geojsonPath: `${process.env.PUBLIC_URL}/geojsons/tx-counties.geojson`,
        },
        {
          label: GEOTYPE_TO_LABELS['congressional-district'],
          value: 'congressional-district',
          subRegions: congressionalSubRegions,
          geojsonPath: `${process.env.PUBLIC_URL}/geojsons/tx-congress.geojson`,
        },
        {
          label: GEOTYPE_TO_LABELS['state-senate-district'],
          value: 'state-senate-district',
          subRegions: stateSenateSubRegions,
          geojsonPath: `${process.env.PUBLIC_URL}/geojsons/tx-state-senate.geojson`,
        },
        {
          label: GEOTYPE_TO_LABELS['state-house-district'],
          value: 'state-house-district',
          subRegions: stateHouseSubRegions,
          geojsonPath: `${process.env.PUBLIC_URL}/geojsons/tx-state-house.geojson`,
        },
        {
          label: GEOTYPE_TO_LABELS['msa'],
          value: 'msa',
          subRegions: msaSubRegions,
          defaultSubRegion: msaSubRegions.find(
            region => region.label === 'Austin-Round Rock-Georgetown, TX',
          ),
          geojsonPath: `${process.env.PUBLIC_URL}/geojsons/tx-msa-senate.geojson`,
        },
        {
          label: GEOTYPE_TO_LABELS['city'],
          value: 'city',
          subRegions: citySubRegions,
          defaultSubRegion: citySubRegions.find(region => region.label === 'El Paso'),
          geojsonPath: null,
        },
      ];

      setRegionOptions(regionOptions);
      setSelectedRegion(regionOptions[0]);
      setSelectedSubRegion(STATE_SUB_REGIONS.find(s => s.label === 'Texas'));
    }
  }, [geoTypes]);

  return (
    <>
      <div className="relative z-20 flex flex-wrap items-start justify-between gap-4 md:flex-nowrap">
        <RegionSelector
          regionOptions={regionOptions}
          selectedRegion={selectedRegion}
          setSelectedRegion={setSelectedRegion}
          selectedSubRegion={selectedSubRegion}
          setSelectedSubRegion={setSelectedSubRegion}
        />
        <DemographicGroupSelector
          demographicGroupOptions={availableDemographicGroups}
          selectedDemographicGroups={selectedDemographicGroups}
          setSelectedDemographicGroups={setSelectedDemographicGroups}
        />
      </div>
      <ChartSelector
        selectedDemographicGroups={selectedDemographicGroups}
        selectedRegion={selectedRegion}
        selectedSubRegion={selectedSubRegion}
      />
    </>
  );
};

export default ChartSection;
