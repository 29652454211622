const Footer = () => {
  return (
    <div className="flex justify-center bg-brand-100 font-display">
      <div className="w-full px-8 py-4 md:px-20 md:py-8">
        <div className="flex items-center justify-between">
          <div className="text-xl font-medium">Texas Immigration Data Portal</div>
          <div>
            <a href="mailto:info@txilc.org" target="_blank" rel="noreferrer">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
