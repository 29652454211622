import { csvFormat } from 'd3-dsv';

const dataToCSVString = data => {
  if (typeof data === 'string') {
    return data;
  }
  if (Array.isArray(data)) {
    return csvFormat(data);
  }
  console.warn('Unsupported data type passed to file download functions.');
  return data;
};

const createLinkAndDownload = (url, downloadName) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = downloadName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadAsCSV = (data, fileName) => {
  const csvData = dataToCSVString(data);
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  createLinkAndDownload(url, `${fileName}.csv`);
};

/**
 * Loads the svg into an image and then writes to canvas so we can
 * donload as png
 */
const svgToPng = svg => {
  return new Promise(resolve => {
    const svgData = svg.outerHTML;
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const svgUrl = URL.createObjectURL(svgBlob);

    // Create canvas that we'll write the svg to
    const canvas = document.createElement('canvas');
    let width = svg.getAttribute('width');
    width = width.substring(0, width.length - 2);
    let height = svg.getAttribute('height');
    height = height.substring(0, height.length - 2);
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    const image = document.createElement('img');
    image.onload = () => {
      // Create a white background
      ctx.fillStyle = '#FFF';
      ctx.fillRect(0, 0, width, height);

      // Draw the image to the canvas
      ctx.drawImage(image, 0, 0, width, height);

      // Convert back to image url
      const url = canvas.toDataURL('image/png');

      URL.revokeObjectURL(svgUrl);
      resolve(url);
    };

    image.src = svgUrl;
  });
};

const OUTPUT_MARGIN = { b: 20, l: 20, r: 30, t: 90 };
const EXPORT_FONT_FAMILY = 'Helvetica, Arial, sans-serif';
const GROUPED_HEADER_PADDING = 20; // Padding around chart header
const CHART_PADDING = 55; // Space between charts if multiple charts

const translate = (x, y) => {
  return `translate(${x}, ${y})`;
};

const createTextEl = textValue => {
  const textEl = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  const textNode = document.createTextNode(textValue);
  textEl.appendChild(textNode);
  return textEl;
};

const createTextStyle = (fontSize, anchor = 'middle') =>
  `fill: rgb(102, 102, 102); font-size: ${fontSize}px; text-anchor: ${anchor};`;

export const downloadChart = ({ downloadName }) => {
  const charts = document.getElementsByClassName('svg-container');
  if (!charts || !charts.length) {
    console.error('No charts found to download');
    return;
  }

  // Determine appropriate output size
  const firstChart = charts[0].getElementsByClassName('main-svg')[0];
  if (!firstChart) {
    console.error('No charts found to download');
    return;
  }
  const { width } = firstChart.getBoundingClientRect();
  const outputWidth = Math.ceil(width) + OUTPUT_MARGIN.l + OUTPUT_MARGIN.r;

  // Create the svg that will hold our export
  const svgExport = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svgExport.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

  // Use font-faces that are likely to be on the user's machine
  svgExport.setAttribute('font-family', EXPORT_FONT_FAMILY);

  // Add a title to the chart
  const titleText = document.getElementById('chart-title-main').innerText;
  const titleEl = createTextEl(titleText);
  titleEl.setAttribute('style', createTextStyle(16) + 'font-weight: bold;');
  titleEl.setAttribute('transform', translate(outputWidth / 2, OUTPUT_MARGIN.t / 2));
  svgExport.appendChild(titleEl);

  // Add a subtitle to the chart
  const subtitleText = document.getElementById('chart-subtitle-main').innerText;
  const subtitleEl = createTextEl(subtitleText);
  subtitleEl.setAttribute('style', createTextStyle(14));
  subtitleEl.setAttribute('transform', translate(outputWidth / 2, OUTPUT_MARGIN.t / 2 + 18));
  svgExport.appendChild(subtitleEl);

  let yOffset = OUTPUT_MARGIN.t; // Will keep track of where to place charts

  // Add all the charts to the output
  for (let i = 0; i < charts.length; i++) {
    const chart = charts[i];
    const chartSvgs = chart.getElementsByClassName('main-svg');
    if (!chartSvgs.length) {
      console.warn('No chart svg found');
      return;
    }

    // Add chart header
    const chartHeaders = document.getElementsByClassName('chart-title');
    if (chartHeaders.length && chartHeaders[i]) {
      const chartHeaderText = chartHeaders[i].innerText;
      const chartHeaderEl = createTextEl(chartHeaderText);
      chartHeaderEl.setAttribute('style', createTextStyle(14) + 'font-weight: bold;');
      chartHeaderEl.setAttribute(
        'transform',
        translate(outputWidth / 2, yOffset + GROUPED_HEADER_PADDING / 2),
      );
      yOffset += GROUPED_HEADER_PADDING;
      svgExport.appendChild(chartHeaderEl);
    }

    const chartSvg = chartSvgs[0];
    const infoGroup = chartSvgs[1].getElementsByClassName('infolayer')[0];

    // Clone the svg nodes so we can make changes to the attributes for export
    const clonedChart = chartSvg.cloneNode(true);
    const clonedInfoGroup = infoGroup.cloneNode(true);

    // Make sure any parts outside of the bounds are visible
    clonedChart.setAttribute('style', 'overflow: visible;');

    // Embed the chart in the download
    const chartWrapper = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    svgExport.appendChild(chartWrapper);
    chartWrapper.appendChild(clonedChart);
    chartWrapper.appendChild(clonedInfoGroup);

    // Ensure the chart is at the proper coordinates
    const { height: chartHeight } = chartSvg.getBoundingClientRect();

    chartWrapper.setAttribute('transform', translate(OUTPUT_MARGIN.l, yOffset));

    yOffset += chartHeight;

    if (i !== charts.length - 1) {
      yOffset += CHART_PADDING; // Add spacing between charts
    }
  }

  // Add source text to the chart
  yOffset += 35; // Padding above source text
  const sourceText = document.getElementById('chart-source').innerText;
  const sourceEl = createTextEl(sourceText);
  sourceEl.setAttribute('style', createTextStyle(14, 'left'));
  sourceEl.setAttribute('transform', translate(OUTPUT_MARGIN.l, yOffset));
  svgExport.appendChild(sourceEl);

  // Set final svg dimensions
  svgExport.setAttribute('width', outputWidth + 'px');
  svgExport.setAttribute('height', yOffset + OUTPUT_MARGIN.b + 'px');

  // Prepare for export by converting to png then downloading
  svgToPng(svgExport)
    .then(url => {
      // Download
      createLinkAndDownload(url, downloadName);
    })
    .catch(error => {
      console.error(error);
    });
};
