import { useNavigate, useParams } from 'react-router';
import { DEFAULT_CATEGORY, DEFAULT_INDICATOR, INDICATORS } from '../consts';
import { useEffect } from 'react';

const useCategoryAndIndicator = ({ redirect = true } = {}) => {
  const navigate = useNavigate();
  const { category, indicator } = useParams();

  useEffect(() => {
    // If the indicator doesn't exist in the const, redirect to the default
    if (!INDICATORS[category] || !INDICATORS[category].indicators[indicator]) {
      if (redirect) {
        navigate(`/explore/${DEFAULT_CATEGORY}/${DEFAULT_INDICATOR}`);
      }
    }
  }, [category, indicator, navigate, redirect]);

  const categoryDetails = INDICATORS[category] || { label: '', indicators: {} };

  return {
    category: categoryDetails,
    indicator,
    indicatorLabel: categoryDetails.indicators[indicator]?.label || '',
  };
};

export default useCategoryAndIndicator;
