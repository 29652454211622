import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_ROOT = '/api';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  endpoints: build => ({
    getIndicatorData: build.query({
      query: ({ geoname, geotype, indicator, year }) => {
        let queryParams = [
          ['geotype', geotype],
          ['indicator', indicator],
        ];

        if (geoname) {
          queryParams.push(['geoname', geoname]);
        }

        if (year) {
          queryParams.push(['year', year]);
        }

        const query = queryParams.length ? `?${queryParams.map(q => q.join('=')).join('&')}` : '';

        return `/indicator-data/search${query}`;
      },
    }),
  }),
});

export const { useGetIndicatorDataQuery } = api;
