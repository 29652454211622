import Select from 'react-select';

const labelClass = 'font-display font-light text-sm';
const selectClass = 'w-56';

const RegionSelector = ({
  regionOptions,
  selectedRegion,
  setSelectedRegion,
  selectedSubRegion,
  setSelectedSubRegion,
}) => {
  return (
    <div className="flex flex-wrap items-center gap-4">
      <div className="flex flex-col gap-2">
        <div className={labelClass}>Select Geographic Region</div>
        <Select
          className={selectClass}
          onChange={value => {
            setSelectedRegion(value);
            if (value.subRegions) {
              if (value.defaultSubRegion) {
                setSelectedSubRegion(value.defaultSubRegion);
              } else {
                setSelectedSubRegion(value.subRegions[0]);
              }
            } else {
              setSelectedSubRegion(null);
            }
          }}
          options={regionOptions}
          isSearchable={true}
          value={selectedRegion}
        />
      </div>
      {selectedRegion.subRegions && (
        <div className="flex flex-col gap-2">
          <div className={labelClass}>Select Geographic Subregion</div>
          <Select
            className={selectClass}
            isSearchable={true}
            onChange={value => setSelectedSubRegion(value)}
            options={selectedRegion.subRegions}
            value={selectedSubRegion}
          />
        </div>
      )}
    </div>
  );
};

export default RegionSelector;
