import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { INDICATORS } from '../consts';

const CollapsableMenu = ({ duration, isOpen }) => {
  const contentRef = useRef();
  const [height, setHeight] = useState(isOpen ? 'auto' : '0');

  const [prevOpenWithValue, setPrevOpenWithValue] = useState({
    label: '',
    indicators: {},
  });

  useEffect(() => {
    if (isOpen) {
      setPrevOpenWithValue(INDICATORS[isOpen]);
    }
  }, [isOpen]);

  // As isOpen is toggled, this effect will trigger the height of the surrounding ref to
  // fit the content. By setting transition css properties on height, it creates the
  // illusion of a collapsing div.
  useLayoutEffect(() => {
    const contentRefRect = contentRef.current.getBoundingClientRect();
    setHeight(isOpen ? `${contentRefRect.height}px` : '0');
  }, [isOpen, prevOpenWithValue]);

  return (
    <div
      className="absolute left-0 top-full z-40 w-full translate-y-4"
      style={{
        height,
        overflow: height === 'auto' ? 'visible' : 'hidden',
        transition: `height ${duration}ms ease-in-out`,
      }}
    >
      <div ref={contentRef}>
        <div className="rounded-sm border-b border-l border-r border-gray-300 bg-[rgba(255,255,255,0.95)] p-4 shadow-sm">
          <div className="mb-4 border-b border-gray-300 pb-2 pl-2 font-display">
            {prevOpenWithValue.label}
          </div>
          <ul>
            {Object.entries(prevOpenWithValue.indicators).map(
              ([indicatorKey, { label: indicatorLabel }]) => (
                <li className="flex w-full text-left text-sm hover:bg-brand-100" key={indicatorKey}>
                  {isOpen ? (
                    <Link className="grow px-2 py-2" to={`/explore/${isOpen}/${indicatorKey}`}>
                      {indicatorLabel}
                    </Link>
                  ) : (
                    <div className="px-2 py-2">{indicatorLabel}</div>
                  )}
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const ExploreMenu = () => {
  const [menuOpen, setMenuOpen] = useState(null);

  const location = useLocation();

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMenuOpen(null);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setMenuOpen(null);
  }, [location]);

  return (
    <div className="border-b border-gray-300">
      <div className="flex justify-center">
        <div className="w-full max-w-screen-xl px-4 pb-4 pt-4 md:px-10 md:pt-8">
          <div className="text-center text-sm font-light md:text-base">
            Explore Immigration Data
          </div>
          <div className="mt-4 flex justify-center" ref={wrapperRef}>
            <ul className="relative flex flex-wrap items-center justify-center gap-4 text-sm sm:flex-nowrap md:gap-8 md:text-base">
              {Object.entries(INDICATORS).map(([key, { label }]) => (
                <li key={key}>
                  <button
                    className="flex items-center gap-2"
                    onClick={() => (menuOpen === key ? setMenuOpen(null) : setMenuOpen(key))}
                  >
                    <ChevronDownIcon className={`h-4 w-4 ${key === menuOpen && 'rotate-180'}`} />
                    <div className="font-display">{label}</div>
                  </button>
                </li>
              ))}
              <CollapsableMenu duration={350} isOpen={menuOpen} />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreMenu;
