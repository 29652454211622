import { GEOTYPE_TO_LABELS } from '../../consts';

export const NoData = ({ children }) => (
  <div className="flex h-[400px] items-center justify-center border border-gray-300 p-2 font-display text-lg">
    {children || 'No data for the current selection'}
  </div>
);

export const NoDataForGeoType = ({ availableGeoTypesForIndicator, indicatorLabel }) => {
  const validGeoTypes = [...availableGeoTypesForIndicator.map(type => GEOTYPE_TO_LABELS[type])];
  let availabilityString;
  if (validGeoTypes.length > 1) {
    let geoTypeString = validGeoTypes.slice(0, validGeoTypes.length - 1).join(', ');
    geoTypeString += ' and ';
    geoTypeString += [validGeoTypes.slice(validGeoTypes.length - 1)].join(' ');
    availabilityString = `Available geographic regions are ${geoTypeString}.`;
  } else {
    availabilityString = `The available geographic region is ${validGeoTypes[0]}.`;
  }
  return (
    <div className="flex h-[400px] items-center justify-center border border-gray-300 p-2 font-display text-lg">
      <div className="max-w-[80%] text-center">
        {indicatorLabel} data is unavailable for the current geographic region.
        <br />
        <span className="text-base text-slate-800">{availabilityString}</span>
      </div>
    </div>
  );
};
