import { Link } from 'react-router-dom';

import SectionLayout from './general/SectionLayout';

const ExploreTile = ({ bgClass, linkTo, title }) => {
  return (
    <Link to={linkTo}>
      <div className="flex w-full max-w-full grow-0 cursor-pointer flex-col shadow-md hover:bg-brand-200 md:max-w-64 md:max-w-none md:grow">
        <div className={`h-52 grow sm:h-36 ${bgClass}`}></div>
        <div className="flex h-24 min-h-24 items-center p-4 font-display text-xl font-medium">
          {title}
        </div>
      </div>
    </Link>
  );
};

const ExploreCTA = () => {
  return (
    <SectionLayout>
      <div className="flex flex-wrap gap-4 lg:flex-nowrap">
        <div className="mr-4 flex w-full flex-col gap-4 lg:w-1/3">
          <h2 className="font-display text-4xl font-medium">Explore the Data</h2>
          <p>
            Ready to explore Texas’s immigration story? From demographics to economic impact and
            legal processes, you can uncover trends over time, from Houston to Dallas to El Paso.
            Visualize data, download it for deeper insights, and stay informed with the latest info.
            This dynamic resource is here to help you understand immigration’s role in shaping
            Texas.
          </p>
        </div>
        <div className="mt-6 grid grow grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:mt-0">
          <ExploreTile
            bgClass="bg-demographics bg-cover bg-center"
            linkTo="explore/demographics/population"
            title="Demographics"
          />
          <ExploreTile
            bgClass="bg-economicimpact bg-cover bg-center"
            linkTo="explore/economic-mobility/educational-attainment"
            title="Economic Mobility"
          />
          <ExploreTile
            bgClass="bg-legalprocess bg-cover"
            linkTo="explore/legal-process/legal-representation-doj"
            title="Legal Process"
          />
        </div>
      </div>
    </SectionLayout>
  );
};

export default ExploreCTA;
