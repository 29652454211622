import { scaleLinear } from 'd3-scale';
import { memo, useMemo } from 'react';
import Plot from 'react-plotly.js';

import {
  CATEGORICAL_COLORS,
  FEATURE_OUTLINE_COLOR,
  FIPS_TO_STATE_ABBR,
  VIZ_TYPES,
} from '../../consts';
import useCategoryAndIndicator from '../../hooks/useCategoryAndIndicator';
import { NoData, NoDataForGeoType } from '../general/NoData';
import { getChartLayout, nestBy } from '../../utils/general';

import metadata from '../../metadata.json';

const SHOW_DATA_RAW = false; // Set this to true to see raw data (useful for debugging)

// Color scale for the map
const COLOR_SCALE = [
  [0, '#ffffb6'],
  [0.2, '#cce69a'],
  [0.4, '#99cd7d'],
  [0.6, '#74b15d'],
  [0.8, '#57953c'],
  [1, '#3a791c'],
];

// Helper function for when SHOW_DATA_RAW is set to true
const showUnique = (data, key) => {
  return [...new Set(data.map(row => row[key]))]
    .sort((a, b) => {
      if (typeof a === 'number') {
        return a - b;
      }
      return a.localeCompare(b);
    })
    .join(', ');
};

const Charts = ({ data, chartType, geojson, geoType, homepage = false }) => {
  const { geoTypesByIndicator, variablesSortOrderByIndicator } = metadata;
  const { indicator, indicatorLabel } = useCategoryAndIndicator({ redirect: !homepage });

  const domainMax = useMemo(() => {
    if (!data) return 1;
    const { dataRange } = data;
    // We need to manually control our own domain for the color scale
    // D3's scale.nice() function does a good job of picking a max value
    // that's close to the data max but nicely rounded
    const scaleNeat = scaleLinear().domain([0, dataRange[1]]).nice();
    return scaleNeat.domain()[1];
  }, [data]);

  const dataType = data?.rows[0]?.data_type;

  const availableGeoTypesForIndicator = geoTypesByIndicator?.[indicator] || [];
  const hasDataForGeoType = availableGeoTypesForIndicator.includes(geoType);

  const plotData = useMemo(() => {
    // We don't render a map for city data because it's not really useful as a choropleth
    if (geoType === 'city' && chartType === VIZ_TYPES.MAP_COMPARISON) {
      return {};
    }
    // Converts the raw data into the form used by plotly
    if (!variablesSortOrderByIndicator) {
      return {};
    }
    // There's no data at all, don't bother nesting by graph type and return empty
    // object. This ensures the no data indicator shows
    if (data?.rows && !data.rows.length) {
      return {};
    }
    const sortByVariable = (a, b) => {
      if (!variablesSortOrderByIndicator[indicator]) {
        return 0;
      }
      const sortA = variablesSortOrderByIndicator[indicator][a];
      const sortB = variablesSortOrderByIndicator[indicator][b];
      return sortA - sortB;
    };
    if (chartType === VIZ_TYPES.TRENDS) {
      return Object.fromEntries(
        Object.entries(nestBy(data.rows, 'varname', 'immigrant_status'))
          .map(([variableName, variableData]) => [
            variableName,
            Object.entries(variableData).map(([statusName, statusData]) => ({
              hovertemplate:
                '<b>%{text.group}</b><br><b>Year</b>: %{x}<br><b>Value</b>: %{text.label}<extra></extra>',
              marker: {
                color: CATEGORICAL_COLORS[statusName],
              },
              name: statusName,
              showlegend: true,
              text: statusData.map(datum => ({ label: datum.value_label, group: statusName })),
              type: 'scatter',
              x: data.years,
              y: statusData.map(datum => datum.value),
            })),
          ])
          .sort((a, b) => {
            return sortByVariable(a[0], b[0]);
          }),
      );
    } else if (chartType === VIZ_TYPES.BY_YEAR) {
      return {
        barChart: Object.entries(nestBy(data.rows, 'immigrant_status', 'varname')).map(
          ([statusName, statusData]) => {
            const sortedVariables = [...data.variables].sort(sortByVariable);
            return {
              hovertemplate:
                '<b>%{text.varname}</b><br><b>%{text.group}</b><br><b>Value</b>: %{text.label}<extra></extra>',
              marker: {
                color: CATEGORICAL_COLORS[statusName],
              },
              name: statusName,
              showlegend: true,
              text: sortedVariables.map(variable => ({
                group: statusName,
                label: statusData[variable][0].value_label,
                varname: statusData[variable][0].varname,
              })),
              type: 'bar',
              x: sortedVariables,
              y: sortedVariables.map(variable => statusData[variable][0].value),
            };
          },
        ),
      };
    } else if (chartType === VIZ_TYPES.MAP_COMPARISON) {
      return Object.fromEntries(
        Object.entries(nestBy(data.rows, 'varname', 'immigrant_status'))
          .sort((a, b) => {
            return sortByVariable(a[0], b[0]);
          })
          .map(([variableName, variableData]) =>
            Object.entries(variableData).map(([statusName, statusData]) => {
              const choroplethData = [
                {
                  colorscale: COLOR_SCALE,
                  colorbar: {
                    len: 0.5,
                    outlinecolor: '#FFF',
                    tickformat:
                      dataType === 'percent' ? '.0%' : dataType === 'dollars' ? '$,.0f' : null,
                    thickness: 20,
                  },
                  geojson,
                  hovertemplate:
                    '<b>%{text.name}</b><br><b>Value</b>: %{text.value}<extra></extra>',
                  marker: {
                    line: {
                      color: 'rgb(255,255,255)',
                      width: 1,
                    },
                  },
                  ...(geojson
                    ? {
                        locationmode: 'geojson-id',
                        featureidkey: 'properties.geoid',
                        locations: statusData.map(d => d.geoid),
                      }
                    : {
                        locationmode: 'USA-states',
                        // Plotly uses state abbreviations for its choropleth mapping
                        // We could pass our own state geojson the way we do for counties,
                        // but this is a little simpler
                        locations: statusData.map(d => FIPS_TO_STATE_ABBR[d.geoid]),
                      }),
                  text: statusData.map(d => ({
                    name: d.geoname,
                    value: d.value_label,
                  })),
                  type: 'choropleth',
                  z: statusData.map(d => d.value),
                  zmin: 0,
                  zmax: domainMax,
                },
              ];
              let traces = choroplethData;

              if (geojson) {
                // We may not have data for all locations. We append a background trace to the
                // plotly plot that will just outline the counties in our outline color.
                traces = [
                  {
                    colorscale: [
                      [0, '#FFF'],
                      [1, '#FFF'],
                    ],
                    geojson,
                    hoverinfo: 'none',
                    marker: {
                      line: {
                        color: FEATURE_OUTLINE_COLOR,
                        width: 1,
                      },
                    },
                    locationmode: 'geojson-id',
                    featureidkey: 'properties.geoid',
                    locations: geojson.features.map(feature => feature.properties.geoid),
                    showscale: false,
                    type: 'choropleth',
                    z: geojson.features.map(() => 1),
                    zmin: 0,
                    zmax: 1,
                  },
                  choroplethData[0],
                ];
              }
              return [`${variableName} - ${statusName}`, traces];
            }),
          )
          .flat(),
      );
    }
    return {};
  }, [
    chartType,
    domainMax,
    data,
    dataType,
    indicator,
    geojson,
    geoType,
    variablesSortOrderByIndicator,
  ]);

  const layout = getChartLayout({
    chartType,
    dataType,
    domainMax,
    useGeojson: Boolean(geojson),
  });

  return (
    <div className="bg-white">
      <div className="flex w-full items-center justify-center">
        <div className="flex w-full flex-col gap-4">
          {Object.entries(plotData).length ? (
            Object.entries(plotData).map(([plotName, plotData]) => (
              <div key={plotName} className="w-full">
                <h2 className="chart-title py-2 text-center font-display text-slate-800">
                  {(chartType === VIZ_TYPES.TRENDS || chartType === VIZ_TYPES.MAP_COMPARISON) &&
                    plotName}
                </h2>
                <div className="h-[400px] w-full">
                  <Plot
                    data={plotData}
                    className="h-[400px] w-full"
                    config={{
                      // Removes ugly plotly UI bar from on top of chart
                      displayModeBar: false,
                    }}
                    layout={layout}
                    useResizeHandler // Required for responsivity on window resize
                  />
                </div>
              </div>
            ))
          ) : hasDataForGeoType ? (
            geoType === 'city' && chartType === VIZ_TYPES.MAP_COMPARISON ? (
              <NoData>Map view unavailable for City geographic regions</NoData>
            ) : (
              <NoData />
            )
          ) : (
            <NoDataForGeoType
              availableGeoTypesForIndicator={availableGeoTypesForIndicator}
              indicatorLabel={indicatorLabel}
            />
          )}
          {data && SHOW_DATA_RAW && (
            <div className="flex flex-col text-sm">
              <div>Rows: {data.rows.length}</div>
              <div>Years: {showUnique(data.rows, 'year')}</div>
              <div>Indicators: {showUnique(data.rows, 'indicator_id')}</div>
              <div>Varnames: {showUnique(data.rows, 'varname')}</div>
              <div>Immigrant Status: {showUnique(data.rows, 'immigrant_status')}</div>
              <div>Data preview:</div>
              <div className="font-mono">{JSON.stringify(data.rows.slice(0, 10))}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Charts);
