import { AboutData } from '../components/AboutData';
import ChartSection from '../components/charts/ChartSection';
import ExploreMenu from '../components/ExploreMenu';
import ExploreMenuAlt from '../components/ExploreMenuAlt';
import SectionHeaderWithLine from '../components/general/SectionHeaderWithLine';
import SectionLayout from '../components/general/SectionLayout';
import IndicatorHeader from '../components/IndicatorHeader';

const Explore = () => (
  <div>
    <ExploreMenu />
    <SectionLayout>
      <div className="flex flex-col gap-8">
        <IndicatorHeader />
        <ChartSection />
      </div>
    </SectionLayout>
    <div className="bg-gray-200">
      <SectionLayout>
        <SectionHeaderWithLine>About the Data</SectionHeaderWithLine>
        <div className="mt-8 md:mt-12">
          <AboutData />
        </div>
      </SectionLayout>
    </div>
    <SectionLayout>
      <ExploreMenuAlt />
    </SectionLayout>
  </div>
);

export default Explore;
