import {
  ArrowRightIcon,
  ArrowTrendingUpIcon,
  CurrencyDollarIcon,
  ScaleIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import Plot from 'react-plotly.js';
import { createSearchParams, Link } from 'react-router-dom';

import { CATEGORICAL_COLORS, VIZ_TYPES } from '../consts';
import { useFetchIndicatorData } from '../hooks/useFetchIndicatorData';
import { getChartLayout, nestBy } from '../utils/general';
import SectionHeaderWithLine from './general/SectionHeaderWithLine';
import SectionLayout from './general/SectionLayout';
import Loader from './general/Loader';

import metadata from '../metadata.json';

const DataGlance = () => {
  const { indicators, variablesSortOrderByIndicator } = metadata;

  // Fetch data for first chart
  const { data: populationData, isLoading: populationDataIsLoading } = useFetchIndicatorData({
    category: 'demographics',
    geotype: 'state',
    geoname: 'Texas',
    indicator: 'population',
    selectedDemographicGroups: ['Not a US citizen', 'Naturalized US citizen'].map(v => ({
      label: v,
      value: v,
    })),
    vizType: VIZ_TYPES.TRENDS,
  });

  // Fetch data for second chart
  const { data: economicImpactData, isLoading: economicImpactDataIsLoading } =
    useFetchIndicatorData({
      category: 'economic-mobility',
      geotype: 'state',
      geoname: 'Texas',
      indicator: 'economic-contributions',
      selectedDemographicGroups: ['Immigrant'].map(v => ({
        label: v,
        value: v,
      })),
      vizType: VIZ_TYPES.BY_YEAR,
      year: 2022,
    });

  // Fetch data for third chart
  const { data: legalRepresentationData, isLoading: legalRepresentationDataIsLoading } =
    useFetchIndicatorData({
      category: 'legal-process',
      geotype: 'state',
      geoname: 'Texas',
      indicator: 'legal-representation-doj',
      selectedDemographicGroups: ['Immigrant in immigration court'].map(v => ({
        label: v,
        value: v,
      })),
      vizType: VIZ_TYPES.TRENDS,
    });

  // Format population data using the same plotly data format as the chart component (line chart)
  const populationPlotlyData =
    populationData && populationData.rows.length
      ? Object.entries(nestBy(populationData.rows, 'varname', 'immigrant_status')).map(
          ([variableName, variableData]) => [
            variableName,
            Object.entries(variableData).map(([statusName, statusData]) => ({
              hovertemplate:
                '<b>%{text.group}</b><br><b>Year</b>: %{x}<br><b>Value</b>: %{text.label}<extra></extra>',
              marker: {
                color: CATEGORICAL_COLORS[statusName],
              },
              name: statusName,
              text: statusData.map(datum => ({ label: datum.value_label, group: statusName })),
              type: 'scatter',
              x: populationData.years,
              y: statusData.map(datum => datum.value),
            })),
          ],
        )[0][1]
      : [];

  const sortByVariable = (a, b) => {
    if (!variablesSortOrderByIndicator?.['economic-contributions']) {
      return 0;
    }
    const sortA = variablesSortOrderByIndicator['economic-contributions'][a];
    const sortB = variablesSortOrderByIndicator['economic-contributions'][b];
    return sortA - sortB;
  };

  // Format economic data using the same plotly data format as the chart component (bar chart)
  const economicImpactPlotlyData =
    economicImpactData && economicImpactData.rows.length
      ? Object.entries(nestBy(economicImpactData.rows, 'immigrant_status', 'varname')).map(
          ([statusName, statusData]) => {
            const sortedVariables = [...economicImpactData.variables].sort(sortByVariable);
            return {
              hovertemplate: '<b>%{text.group}</b><br><b>Value</b>: %{text.label}<extra></extra>',
              marker: {
                color: CATEGORICAL_COLORS[statusName],
              },
              name: statusName,
              showlegend: true,
              text: sortedVariables.map(variable => ({
                label: statusData[variable][0].value_label,
                group: statusName,
              })),
              type: 'bar',
              x: sortedVariables,
              y: sortedVariables.map(variable => statusData[variable][0].value),
            };
          },
        )
      : [];

  // Format economic data using the same plotly data format as the chart component (line chart)
  // NB: This differs a bit from how we usually nest the data as we're plotting varname on the same
  // chart
  const legalRepresentationPlotlyData =
    legalRepresentationData && legalRepresentationData.rows.length
      ? Object.entries(nestBy(legalRepresentationData.rows, 'immigrant_status', 'varname')).map(
          ([variableName, variableData]) => [
            variableName,
            Object.entries(variableData).map(([statusName, statusData]) => ({
              hovertemplate:
                '<b>%{text.group}</b><br><b>Year</b>: %{x}<br><b>Value</b>: %{text.label}<extra></extra>',
              marker: {
                color: CATEGORICAL_COLORS[statusName],
              },
              name: statusName,
              text: statusData.map(datum => ({ label: datum.value_label, group: statusName })),
              type: 'scatter',
              x: legalRepresentationData.years,
              y: statusData.map(datum => datum.value),
            })),
          ],
        )[0][1]
      : [];

  const TILES = [
    {
      blurb: 'Track the growth and changes in Texas’s immigrant population over the years.',
      chartLabel: 'Population -- Texas',
      definition: indicators?.population?.definition,
      icon: ArrowTrendingUpIcon,
      label: 'Population Trends',
      link: 'explore/demographics/population',
      loading: populationDataIsLoading,
      plotData: populationPlotlyData,
      plotLayout: getChartLayout({
        chartType: VIZ_TYPES.TRENDS,
        dataType: populationData?.rows[0]?.data_type,
        domainMax: populationData.rows.reduce((a, b) => Math.max(a, b.value), -Infinity) * 1.2,
        useGeojson: false,
      }),
      vizType: VIZ_TYPES.TRENDS,
    },
    {
      blurb:
        'Discover how immigrant communities contribute to Texas’s economy through taxes and consumer spending.',
      chartLabel: 'Economic Mobility -- Texas',
      definition: indicators?.['economic-contributions']?.definition,
      icon: CurrencyDollarIcon,
      label: 'Economic Impact',
      link: 'explore/economic-mobility/economic-contributions',
      loading: economicImpactDataIsLoading,
      plotData: economicImpactPlotlyData,
      plotLayout: getChartLayout({
        chartType: VIZ_TYPES.BY_YEAR,
        dataType: economicImpactData?.rows[0]?.data_type,
        domainMax: economicImpactData.rows.reduce((a, b) => Math.max(a, b.value), -Infinity) * 1.2,
        useGeojson: false,
      }),
      vizType: VIZ_TYPES.BY_YEAR,
    },
    {
      blurb:
        'Track trends in legal representation for immigrants navigating Texas’s immigration courts over time.',
      chartLabel: 'Legal Process -- Texas',
      definition: indicators?.['legal-representation-doj']?.definition,
      icon: ScaleIcon,
      label: 'Immigrant legal representation',
      link: 'explore/legal-process/legal-representation-doj',
      loading: legalRepresentationDataIsLoading,
      plotData: legalRepresentationPlotlyData,
      plotLayout: getChartLayout({
        chartType: VIZ_TYPES.TRENDS,
        dataType: legalRepresentationData?.rows[0]?.data_type,
        domainMax:
          legalRepresentationData.rows.reduce((a, b) => Math.max(a, b.value), -Infinity) * 1.2,
        useGeojson: false,
      }),
      vizType: VIZ_TYPES.TRENDS,
    },
  ];

  const [activeTile, setActiveTile] = useState(0);

  return (
    <div className="bg-gray-200">
      <SectionLayout>
        <SectionHeaderWithLine>Data at a Glance</SectionHeaderWithLine>
        <div className="mt-8 flex flex-col gap-4 bg-white p-8 md:mt-12">
          <div className="flex items-center gap-2 md:justify-between">
            <div>
              <div className="text-lg" id="chart-title-main">
                {TILES[activeTile].chartLabel}
              </div>
              <div className="text-sm font-light" id="chart-subtitle-main">
                {TILES[activeTile].definition}
              </div>
            </div>
            <div className="hidden md:block">
              <Link
                className=" cursor-pointer text-brand-700 hover:text-brand-900"
                to={{
                  pathname: TILES[activeTile].link,
                  search: createSearchParams({ viz_type: TILES[activeTile].vizType }).toString(),
                }}
              >
                <div className="inline-flex items-center gap-2">
                  <div>Explore this data</div>
                  <ArrowRightIcon className="h-4 w-4 translate-y-px" />
                </div>
              </Link>
            </div>
          </div>
          <div className="flex min-h-[380px] w-full items-center justify-center">
            {TILES[activeTile].loading ? (
              <Loader />
            ) : (
              <Plot
                data={TILES[activeTile].plotData || []}
                className="h-[380px] w-full"
                config={{
                  // Removes ugly plotly UI bar from on top of chart
                  displayModeBar: false,
                }}
                layout={TILES[activeTile].plotLayout}
                useResizeHandler // Required for responsivity on window resize
              />
            )}
          </div>
          <div className="block md:hidden">
            <Link
              className=" cursor-pointer text-brand-700 hover:text-brand-900"
              to={{
                pathname: TILES[activeTile].link,
                search: createSearchParams({ viz_type: TILES[activeTile].vizType }).toString(),
              }}
            >
              <div className="inline-flex items-center gap-2">
                <div>Explore this data</div>
                <ArrowRightIcon className="h-4 w-4 translate-y-px" />
              </div>
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-8">
          <ul className="grid grid-cols-[1fr_2px_1fr_2px_1fr] gap-x-3">
            {TILES.map((t, i) => (
              <Fragment key={t.label}>
                <li
                  className={`bg-white ${i === activeTile ? 'opacity-100' : 'opacity-60'} relative cursor-pointer`}
                  onClick={() => setActiveTile(i)}
                >
                  {i === activeTile && (
                    <div className="absolute left-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white md:h-8 md:w-8"></div>
                  )}
                  <div className="mx-2 my-4 grid grid-cols-1 gap-x-4 gap-y-3 md:mx-8 md:my-8 lg:grid-cols-[24px_1fr]">
                    <div className="justify-self-center lg:justify-self-start">
                      <t.icon className="h-[24px] w-[24px] text-brand-800" />
                    </div>
                    <div className="break-words text-center font-display text-xs font-semibold text-brand-800 sm:text-sm md:text-left md:text-base">
                      {t.label}
                    </div>
                    <div className="hidden lg:block" />
                    <p className="hidden md:block">{t.blurb}</p>
                  </div>
                </li>
                {i !== TILES.length - 1 && (
                  <div className="my-4 w-px justify-self-center bg-brand-600" />
                )}
              </Fragment>
            ))}
          </ul>
        </div>
      </SectionLayout>
    </div>
  );
};

export default DataGlance;
